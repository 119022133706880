/**
 * This file is generated (ejected from Refine)
 * by using command `swizzle` (npm run refine swizzle)
 *
 * Read more
 * https://refine.dev/docs/3.xx.xx/packages/documentation/cli/#swizzle
 */
import { CanAccess } from '@/components';
import appLogo from '@/images/app-logo.svg';
import { useRefineWarnModal } from '@/utils/hooks';
import { CaretLeft, Gauge, ListBullets, SignOut } from '@onefish/icons-react';
import type { RefineThemedLayoutV2SiderProps } from '@refinedev/antd';
import { useThemedLayoutContext } from '@refinedev/antd';
import {
  ITreeMenu,
  pickNotDeprecated,
  useActiveAuthProvider,
  useIsExistAuthentication,
  useLogout,
  useMenu,
  useNavigation,
  useRefineContext,
  useTranslate,
} from '@refinedev/core';
import { Button, ConfigProvider, Divider, Drawer, Grid, Layout, Menu, theme } from 'antd';
import React, { CSSProperties, ReactNode } from 'react';
import styles from './styles.module.css';
import { flag } from '@onefish/flag';

const { SubMenu } = Menu;
const { useToken } = theme;

const drawerButtonStyles: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  top: 74,
  zIndex: 999,
  width: '20px',
  border: 'none',
  height: '75px',
  borderRadius: 0,
  clipPath: 'polygon(0px 0px, 100% 25%, 100% 75%, 0% 100%)',
  backgroundColor: 'var(--color-grey-50)',
};

const sidebarShadow = '0px 2px 8px 0px rgba(0, 0, 0, 0.15)';

const stickyDrawerButtonStyles: CSSProperties = {
  ...drawerButtonStyles,
  position: 'absolute',
  left: '100%',
  boxShadow: sidebarShadow,
};

const menuItemStyles: CSSProperties = {
  whiteSpace: 'normal',
  lineHeight: 'normal',
};

const sidebarContentWrapper: CSSProperties = {
  height: '100%',
  boxShadow: sidebarShadow,
};

type StickyDrawerButtonProps = {
  onClick: () => void;
};

const StickyDrawerButton = ({ onClick }: StickyDrawerButtonProps) => {
  return (
    <Button style={stickyDrawerButtonStyles} size="large" onClick={onClick} icon={<CaretLeft size={20} />}></Button>
  );
};

type LogoProps = {
  collapsed: string | boolean;
};

const RenderToTitle = ({ collapsed }: LogoProps) => {
  return (
    <img
      src={appLogo}
      style={
        collapsed
          ? { width: '50%', height: '36px', objectFit: 'cover', objectPosition: 'left', transition: 'all' }
          : { width: '100%' }
      }
    />
  );
};

type ThemeProviderProps = {
  children: ReactNode;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemBorderRadius: 0,
            itemMarginInline: 0,
            itemSelectedBg: 'var(--color-observatory-70)',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export const Sider: React.FC<RefineThemedLayoutV2SiderProps> = ({
  render,
  meta,
  fixed,
  activeItemDisabled = false,
}) => {
  const { token } = useToken();
  const { siderCollapsed, setSiderCollapsed, mobileSiderOpen, setMobileSiderOpen } = useThemedLayoutContext();

  const isExistAuthentication = useIsExistAuthentication();
  const { warnOnCallback, contextHolder } = useRefineWarnModal();
  const { push } = useNavigation();
  const translate = useTranslate();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu({ meta });
  const breakpoint = Grid.useBreakpoint();
  const { hasDashboard } = useRefineContext();
  const authProvider = useActiveAuthProvider();
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const isMobile = typeof breakpoint.lg === 'undefined' ? false : !breakpoint.lg;

  const isSiderCollapsed = (!isMobile && siderCollapsed) || (isMobile && !mobileSiderOpen);

  const navigate = (path: string) => {
    warnOnCallback(() => {
      push(path);
    });
  };

  const renderTreeView = (tree: ITreeMenu[], selectedKey?: string) => {
    return tree.map((item: ITreeMenu) => {
      const { route, key, name, children, parentName, meta, options } = item;
      // console.log(meta?.isEnable);

      if (children.length > 0) {
        return (
          <CanAccess
            key={item.key}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: item,
            }}
          >
            {meta?.isEnable ? (
              flag(
                meta?.isEnable,

                <SubMenu
                  data-testid={'sider' + item.key?.replaceAll('/', '-')?.toLowerCase()}
                  key={item.key}
                  icon={meta?.icon ?? <ListBullets size={20} />}
                  title={meta?.label}
                  className={
                    !isSiderCollapsed ? styles['ant-override-submenu'] : styles['ant-override-submenu-collapsed']
                  }
                >
                  {renderTreeView(children, selectedKey)}
                </SubMenu>,
              )
            ) : (
              <SubMenu
                data-testid={'sider' + item.key?.replaceAll('/', '-')?.toLowerCase()}
                key={item.key}
                icon={meta?.icon ?? <ListBullets size={20} />}
                title={meta?.label}
                className={
                  !isSiderCollapsed ? styles['ant-override-submenu'] : styles['ant-override-submenu-collapsed']
                }
              >
                {renderTreeView(children, selectedKey)}
              </SubMenu>
            )}
          </CanAccess>
        );
      }
      const isSelected = key === selectedKey;
      const isRoute = !(
        pickNotDeprecated(meta?.parent, options?.parent, parentName) !== undefined && children.length === 0
      );

      const linkStyle: CSSProperties = {
        width: '100%',
        pointerEvents: activeItemDisabled && isSelected ? 'none' : 'initial',
      };

      return (
        <>
          {meta?.isEnable ? (
            flag(
              meta?.isEnable,
              <CanAccess
                key={item.key}
                resource={name.toLowerCase()}
                action="list"
                params={{
                  resource: item,
                }}
              >
                <Menu.Item
                  data-testid={'sider' + item.key?.replaceAll('/', '-')?.toLowerCase()}
                  key={item.key}
                  icon={meta?.icon ?? (isRoute && <ListBullets size={20} />)}
                  className={styles['ant-override-menu-item']}
                  style={{
                    ...linkStyle,
                    ...(!isSiderCollapsed ? menuItemStyles : {}),
                  }}
                  onClick={() => {
                    navigate(route ?? '');
                  }}
                >
                  {meta?.label}
                  {!siderCollapsed && isSelected && <div className="ant-menu-tree-arrow" />}
                </Menu.Item>
              </CanAccess>,
            )
          ) : (
            <CanAccess
              key={item.key}
              resource={name.toLowerCase()}
              action="list"
              params={{
                resource: item,
              }}
            >
              <Menu.Item
                data-testid={'sider' + item.key?.replaceAll('/', '-')?.toLowerCase()}
                key={item.key}
                icon={meta?.icon ?? (isRoute && <ListBullets size={20} />)}
                className={styles['ant-override-menu-item']}
                style={{
                  ...linkStyle,
                  ...(!isSiderCollapsed ? menuItemStyles : {}),
                }}
                onClick={() => {
                  navigate(route ?? '');
                }}
              >
                {meta?.label}
                {!siderCollapsed && isSelected && <div className="ant-menu-tree-arrow" />}
              </Menu.Item>
            </CanAccess>
          )}
        </>
      );
    });
  };

  const handleLogout = () => {
    warnOnCallback(() => {
      mutateLogout();
    });
  };

  const logout = isExistAuthentication && (
    <Button
      type="text"
      onClick={handleLogout}
      style={{
        padding: '0',
        width: '100%',
        height: '45px',
      }}
      data-testid="btn_logout"
    >
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          paddingLeft: '15px',
          fontSize: '14px',
          justifyContent: isSiderCollapsed ? 'center' : 'flex-start',
        }}
      >
        <SignOut style={{ marginRight: '15px' }} />
        {!isSiderCollapsed && 'Sign Out'}
      </span>
    </Button>
  );

  const dashboard = hasDashboard ? (
    <Menu.Item
      data-testid="sider-dashboard"
      key="dashboard"
      className={styles['ant-override-menu-item']}
      icon={<Gauge size={20} />}
      style={!isSiderCollapsed ? menuItemStyles : undefined}
      onClick={() => {
        navigate('/');
      }}
    >
      {translate('dashboard.title', 'Dashboard')}
      {!siderCollapsed && selectedKey === '/' && <div className="ant-menu-tree-arrow" />}
    </Menu.Item>
  ) : null;

  const items = renderTreeView(menuItems, selectedKey);

  const renderSider = () => {
    if (render) {
      return render({
        dashboard,
        items,
        logout,
        collapsed: siderCollapsed,
      });
    }

    return (
      <>
        {dashboard}
        {items}
      </>
    );
  };

  const renderMenu = () => {
    return (
      <Menu
        selectedKeys={selectedKey ? [selectedKey] : []}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        style={{
          padding: '8px 16px',
          border: 'none',
          overflow: 'auto',
          height: 'calc(100% - 110px)',
        }}
        onClick={() => {
          setMobileSiderOpen(false);
        }}
      >
        {renderSider()}
      </Menu>
    );
  };

  const renderDrawerSider = () => {
    return (
      <ThemeProvider>
        <Drawer
          open={mobileSiderOpen}
          onClose={() => {
            setMobileSiderOpen(false);
          }}
          placement="left"
          closable={false}
          bodyStyle={{
            padding: 0,
          }}
          maskClosable={true}
          width={200}
        >
          <Layout>
            <Layout.Sider
              style={{
                height: `100vh`,
                backgroundColor: token.colorBgContainer,
                borderRight: `1px solid ${token.colorBgElevated}`,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  padding: '0 16px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '64px',
                  backgroundColor: token.colorBgElevated,
                  borderBottom: `1px solid ${token.colorBorder}`,
                }}
              >
                <RenderToTitle collapsed={siderCollapsed} />
              </div>

              <div style={sidebarContentWrapper}>
                {renderMenu()}
                <Divider style={{ margin: '0' }} />
                {logout}
              </div>
            </Layout.Sider>
          </Layout>
          <StickyDrawerButton
            onClick={() => {
              setMobileSiderOpen(false);
            }}
          />
        </Drawer>
        {contextHolder}
      </ThemeProvider>
    );
  };

  if (isMobile) {
    return renderDrawerSider();
  }

  const siderStyles: React.CSSProperties = {
    backgroundColor: token.colorBgContainer,
    borderRight: `1px solid ${token.colorBgElevated}`,
  };

  if (fixed) {
    siderStyles.position = 'fixed';
    siderStyles.top = 0;
    siderStyles.height = '100vh';
    siderStyles.zIndex = 9999;
  }

  return (
    <ThemeProvider>
      {fixed && (
        <div
          style={{
            width: siderCollapsed ? '80px' : '240px',
            transition: 'all 0.2s',
          }}
        />
      )}
      <Layout.Sider
        style={siderStyles}
        collapsed={siderCollapsed}
        onCollapse={(collapsed, type) => {
          if (type === 'clickTrigger') {
            setSiderCollapsed(collapsed);
          }
        }}
        width={240}
        collapsedWidth={80}
        breakpoint="lg"
      >
        <div
          style={{
            width: siderCollapsed ? '80px' : '240px',
            padding: siderCollapsed ? '0' : '0 16px',
            display: 'flex',
            justifyContent: siderCollapsed ? 'center' : 'flex-start',
            alignItems: 'center',
            height: '64px',
            backgroundColor: token.colorBgElevated,
            fontSize: '14px',
            borderBottom: `1px solid ${token.colorBorder}`,
          }}
        >
          <RenderToTitle collapsed={siderCollapsed} />
        </div>
        <div style={sidebarContentWrapper}>
          {renderMenu()}
          <Divider style={{ margin: '0' }} />
          {logout}
        </div>
      </Layout.Sider>
      {contextHolder}
    </ThemeProvider>
  );
};
